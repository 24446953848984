import axiosConfig from '@/utils/AxiosConfig';

const SANCTUM_ENDPOINT = '/sanctum/csrf-cookie';

export default {
  async login(payload) {
    await axiosConfig.auth.get(SANCTUM_ENDPOINT);
    return axiosConfig.auth.post('/auth/login', payload);
  },
  logout() {
    return axiosConfig.auth.post('/auth/logout');
  },
  async forgotPassword(payload) {
    await axiosConfig.auth.get(SANCTUM_ENDPOINT);
    return axiosConfig.api.post('/password/forgot-password', payload);
  },
  getAuthUser() {
    return axiosConfig.api.get('/users/auth');
  },
  async resetPassword(payload) {
    await axiosConfig.auth.get(SANCTUM_ENDPOINT);
    return axiosConfig.api.post('/password/reset-password', payload);
  },
  updatePassword(payload) {
    return axiosConfig.auth.put('/user/password', payload);
  },
  async registerUser(payload) {
    await axiosConfig.auth.get(SANCTUM_ENDPOINT);
    return axiosConfig.auth.post('/auth/register', payload);
  },
  sendVerification(payload) {
    return axiosConfig.api.post('/emails/verification-notification', payload);
  },
  verifyEmail(id, hash, query) {
    return axiosConfig.api.get(`/emails/verify/${id}/${hash}?${query}`);
  },
  updateUser(payload) {
    return axiosConfig.auth.put('/user/profile-information', payload);
  },
};
