import { getError } from '@/utils/helpers';
import CommentService from '@/services/CommentService';
import router from '@/router';

export const state = {
  comments: null,
  comment: null,
  loading: false,
  user_clicked_on_comment_icon: false,
  current_page: 1,
  current_status: null,
  per_page: 10,
  error: null,
};

export const mutations = {
  SET_COMMENTS(state, comments) {
    state.comments = comments;
  },
  SET_COMMENT(state, comment) {
    state.comment = comment;
  },
  UPDATE_COMMENT_CONTENT(state, content) {
    state.comment.content = content;
  },
  DELETE_COMMENT(state, id) {
    state.comments = state.comments.filter((item) => item.id !== id);
  },
  SET_USER_CLICKED_ON_COMMENT_ICON(state, value) {
    state.user_clicked_on_comment_icon = value;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_CURRENT_PAGE(state, page) {
    state.current_page = page;
  },
  SET_CURRENT_STATUS(state, status) {
    state.current_status = status;
  },
  SET_PER_PAGE(state, per_page) {
    state.per_page = per_page;
  },
};

export const actions = {
  async getComments({ commit, state }, { id }) {
    commit('SET_LOADING', true);

    try {
      const { data, status } = await CommentService.getUserComments(
        id,
        state.current_status,
        state.current_page,
        state.per_page
      );
      if (status === 404) {
        window.location.href = '#/404';
        return;
      }
      const comments = data.data.map((comment) => {
        return {
          id: comment.id,
          ...comment.attributes,
          post: {
            id: comment.relationships.article.id,
            title: comment.relationships.article.attributes.title,
          },
        };
      });
      commit('SET_COMMENTS', {
        items: comments,
        meta: data.meta,
        total: data.total,
        per_page: data.perPage,
        last_page: data.lastPage,
        current_page: data.currentPage,
        links: data.links,
      });
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async getComment({ commit }, { id, commentId }) {
    commit('SET_LOADING', true);

    try {
      const response = await CommentService.getUserComment(id, commentId);
      if (response.status === 404) {
        window.location.href = '#/404';
        return;
      }
      const data = {
        id: response.data.id,
        ...response.data.attributes,
        post: {
          id: response.data.relationships.article.id,
          ...response.data.relationships.article.attributes,
          author: {
            id: response.data.relationships.article.relationships.author.id,
            ...response.data.relationships.article.relationships.author.attributes,
          },
          tags: response.data.relationships.article.relationships.tags.data.map((tag) => {
            return {
              id: tag.id,
              ...tag.attributes,
            };
          }),
        },
        replies: response.data.relationships.replies.map((reply) => {
          return {
            id: reply.id,
            ...reply.attributes,
            author: {
              id: reply.relationships.author.id,
              ...reply.relationships.author.attributes,
            },
            replies: reply.relationships.replies.map((nestedReply) => {
              if (nestedReply) {
                return {
                  id: nestedReply.id,
                  ...nestedReply.attributes,
                  author: {
                    id: nestedReply.relationships.author.id,
                    ...nestedReply.relationships.author.attributes,
                  },
                };
              } else {
                return null;
              }
            }),
          };
        }),
      };
      commit('SET_COMMENT', data);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async deleteComment({ commit }, { id, userId }) {
    if (confirm('Are you sure you want to delete this comment')) {
      commit('SET_LOADING', true);
      try {
        await CommentService.deleteComment(userId, id);
        if (router.currentRoute._rawValue.path !== '/comments') {
          router.push('/comments');
          return;
        } else {
          commit('DELETE_COMMENT', id);
        }
        commit('SET_ERROR', null);
        commit('SET_LOADING', false);
      } catch (error) {
        commit('SET_LOADING', false);
        commit('SET_ERROR', getError(error));
      }
    }
  },
  async updateComment({ commit }, { id, commentId, payload }) {
    commit('SET_LOADING', true);
    try {
      await CommentService.updateComment(id, commentId, payload);
      commit('SET_COMMENT', null);
      commit('SET_ERROR', null);
      router.push({ name: 'Comments', params: {} });
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async create({ commit, dispatch }, { payload }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await CommentService.store(payload);
      const comment = CommentService.constructSimpleComment(data);
      const article = data.relationships.article;
      dispatch('blog/updateArticleComments', { comment, article }, { root: true }).then(
        () => {
          commit('SET_LOADING', false);
          commit('SET_ERROR', null);
        }
      );
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async toggleLike({ commit, dispatch }, { index, payload }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await CommentService.toggleLike(payload);

      dispatch('blog/updateArticleCommentsCount', { index }, { root: true })
        .then(() => {
          commit('SET_LOADING', false);
          commit('SET_ERROR', null);
        })
        .finally(() => {
          return data;
        });
      return data;
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
};

export const getters = {
  comments: (state) => state.comments,
  comment: (state) => state.comment,
  user_clicked_on_comment_icon: (state) => state.user_clicked_on_comment_icon,
  loading: (state) => state.loading,
  error: (state) => state.error,
  current_page: (state) => state.current_page,
  current_status: (state) => state.current_status,
  per_page: (state) => state.per_page,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
