import { getError } from '@/utils/helpers';
import ContentService from '@/services/ContentService';

const page_actions = {
  home: 'SET_HOME_PAGE',
  'about-us': 'SET_ABOUT_PAGE',
  signin: 'SET_LOGIN_PAGE',
  signup: 'SET_REGISTER_PAGE',
  checkout: 'SET_CHECKOUT_PAGE',
};
export const state = {
  home: null,
  about: null,
  login: null,
  register: null,
  services: null,
  service: null,
  current_service: null,
  quotes: null,
  team: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_HOME_PAGE(state, home) {
    state.home = home;
  },
  SET_ABOUT_PAGE(state, about) {
    state.about = about;
  },
  SET_LOGIN_PAGE(state, login) {
    state.login = login;
  },
  SET_REGISTER_PAGE(state, register) {
    state.register = register;
  },
  SET_SERVICES(state, services) {
    state.services = services;
  },
  SET_SERVICE(state, service) {
    state.service = service;
  },
  SET_CURRENT_SERVICE(state, current_service) {
    state.current_service = current_service;
  },
  SET_QUOTES(state, quotes) {
    state.quotes = quotes;
  },
  SET_TEAM(state, team) {
    state.team = team;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async getServices({ commit }) {
    commit('SET_LOADING', true);

    try {
      const { data } = await ContentService.getServices();
      commit('SET_SERVICES', data.data);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async getService({ commit, state }) {
    commit('SET_LOADING', true);

    try {
      const { data } = await ContentService.getService(state.current_service);
      commit('SET_SERVICE', data);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async getQuotes({ commit }) {
    commit('SET_LOADING', true);

    try {
      const { data } = await ContentService.getCustomersQuote();
      const quotes = data.data.map((quote) => {
        return {
          id: quote.id,
          ...quote.attributes,
        };
      });
      commit('SET_QUOTES', quotes);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async getTeam({ commit }) {
    commit('SET_LOADING', true);

    try {
      const { data } = await ContentService.getTeam();
      const team = data.data.map((employee) => {
        return {
          id: employee.id,
          ...employee.attributes,
          role: employee.attributes.jobTitle,
        };
      });
      commit('SET_TEAM', team);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async getPage({ commit }, { page }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await ContentService.getPage(page);
      const pageData = {
        page: {
          id: data.id,
          ...data.attributes,
        },
        header: {
          id: data.relationships.header.id,
          ...data.relationships.header.attributes,
        },
        section: {
          id: data.relationships.section?.id,
          ...data.relationships.section?.attributes,
          content: {
            id: data.relationships.section?.relationships?.content?.id,
            ...data.relationships.section?.relationships?.content?.attributes,
          },
        },
      };
      commit(page_actions[page], pageData);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
};

export const getters = {
  home: (state) => state.home,
  about: (state) => state.about,
  login: (state) => state.login,
  register: (state) => state.register,
  services: (state) => state.services,
  service: (state) => state.service,
  quotes: (state) => state.quotes,
  team: (state) => state.team,
  loading: (state) => state.loading,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
