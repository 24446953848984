import axiosConfig from '@/utils/AxiosConfig';

export default {
  async getUserComments(id, status = null, page = 1, perPage = 10) {
    if (status?.toLowerCase() === 'all' || status === null) {
      return axiosConfig.api.get(`/users/${id}/comments?page=${page}&perPage=${perPage}`);
    }
    return axiosConfig.api.get(
      `/users/${id}/comments?status=${status}&page=${page}&perPage=${perPage}`
    );
  },
  async getUserComment(id, commentId) {
    return axiosConfig.api.get(`/users/${id}/comments/${commentId}`);
  },
  async deleteComment(id, commentId) {
    return axiosConfig.api.delete(`/users/${id}/comments/${commentId}`);
  },
  async updateComment(id, commentId, payload) {
    return axiosConfig.api.put(`/users/${id}/comments/${commentId}/`, payload);
  },
  async store(payload) {
    return axiosConfig.api.post('/blog/posts/comments', payload);
  },
  async toggleLike(payload) {
    return axiosConfig.api.post('/blog/posts/comments/like', payload);
  },
  constructSimpleComment(comment) {
    return {
      id: comment.id,
      ...comment.attributes,
      author: {
        id: comment.relationships.author.id,
        ...comment.relationships.author.attributes,
      },
    };
  },
};
