import axiosConfig from '@/utils/AxiosConfig';

/**
 * ProductService provides methods for interacting with the products API.
 */
export default {
  /**
   * Retrieve a list of products.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to a list of products.
   */
  getProducts() {
    return axiosConfig.api.get('/products');
  },
  /**
   * @param {number} id The ID of the product to retrieve.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the specified product.
   */
  getProduct(id) {
    return axiosConfig.api.get(`/products/${id}`);
  },

  /**
   * Retrieve a list of related products.
   *
   * @param {number} id The ID of the product to retrieve related products for.
   * @returns {Promise<AxiosResponse>} A promise that resolves to a list of related products.
   */
  getRelatedProducts(id) {
    return axiosConfig.api.get(`/products/${id}/related`);
  },

  /**
   * Retrieve a list of best selling products.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to a list of best selling products.
   */
  getBestSellingProducts() {
    return axiosConfig.api.get('/products/best-selling');
  },

  /**
   * Retrieve a list of featured products.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to a list of featured products.
   */
  getFeaturedProducts() {
    return axiosConfig.api.get('/products/featured');
  },

  /**
   * Retrieve a list of popular products.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to a list of popular products.
   */
  getPopularProducts() {
    return axiosConfig.api.get('/products/popular');
  },

  /**
   * Retrieve a list of categories.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to a list of categories.
   */
  getCategories(query = '') {
    return axiosConfig.api.get(`/products/categories?${query}`);
  },
  /**
   * Retrieve a single category by ID.
   *
   * @param {number} id The ID of the category to retrieve.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the specified category.
   */
  getCategory(id) {
    return axiosConfig.api.get(`/products/categories/${id}`);
  },
  /**
   * Retrieve a list of categories with subcategories.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to a list of categories with subcategories.
   */
  getCategoriesWithSubcategories() {
    return axiosConfig.api.get('/products/categories/all');
  },

  /**
   * Retrieve a list of products for a given category.
   *
   * @param {number} id The ID of the category to retrieve products for.
   * @param query
   * @returns {Promise<AxiosResponse>} A promise that resolves to a list of products.
   */
  getProductsByCategory(id, query = '') {
    return axiosConfig.api.get(`/products/categories/${id}/all?${query}`);
  },

  /**
   * Retrieve a list of best selling products for a given category.
   *
   * @param {number} id The ID of the category to retrieve best selling products for.
   * @returns {Promise<AxiosResponse>} A promise that resolves to a list of best selling products.
   */
  getBestSellingProductsByCategory(id) {
    return axiosConfig.api.get(`/products/categories/${id}/best-selling`);
  },

  /**
   * Retrieve a list of popular products for a given category.
   *
   * @param {number} id The ID of the category to retrieve popular products for.
   * @returns {Promise<AxiosResponse>} A promise that resolves to a list of popular products.
   */
  getPopularProductsByCategory(id) {
    return axiosConfig.api.get(`/products/categories/${id}/popular-products`);
  },

  constructProduct(product) {
    return {
      type: product.type.replace(product.type[0], product.type[0].toUpperCase()),
      id: product.id,
      ...product.attributes,
      quantity: 1,
      category: {
        id: product.relationships.category.id,
        ...product.relationships.category.attributes,
      },
      productType: {
        id: product.relationships.type.id,
        ...product.relationships.type.attributes,
      },
      ingredients: product.relationships.ingredients.data.map((ingredient) => {
        return {
          id: ingredient.id,
          ...ingredient.attributes,
        };
      }),
      reviews: !product.relationships.reviews
        ? []
        : product.relationships.reviews.data.map((review) => {
            return {
              id: review.id,
              ...review.attributes,
              author: {
                id: review.relationships.author.id,
                ...review.relationships.author.attributes,
              },
            };
          }),
    };
  },
};
