import axiosConfig from '@/utils/AxiosConfig';

export default {
  async getOrders(page = 1, perPage = 10) {
    return axiosConfig.api.get(`/orders?page=${page}&perPage=${perPage}`);
  },
  async getOrdersByStatus(status = null, page = 1, perPage = 10) {
    if (status.toLowerCase() === 'all') {
      return this.getOrders(page, perPage);
    }
    return axiosConfig.api.get(
      `/orders?status=${status}&page=${page}&perPage=${perPage}`
    );
  },
  async getOrder(id) {
    return axiosConfig.api.get(`/orders/${id}`);
  },
  async getOrderByStatus({ status, id }) {
    return axiosConfig.api.get(`/orders/${status}/${id}`);
  },
  async createOrder(payload) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.post(`/orders`, payload);
  },
  async confirmOrder(id) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.patch(`/orders/${id}/confirm`);
  },
  async cancelOrder(id) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.patch(`/orders/${id}/cancel`);
  },
  async deleteOrder(id) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.delete(`/orders/${id}`);
  },
  async getEstimatedDeliveryTime() {
    return axiosConfig.api.get('/orders/estimated-delivery-time');
  },
};
