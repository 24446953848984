import { createStore, createLogger } from 'vuex';
import auth from './modules/auth';
import order from './modules/order.js';
import cart from './modules/cart';
import favorite from './modules/favorite';
import comment from './modules/comment';
import dashboard from './modules/dashboard';
import review from './modules/review';
import blog from './modules/blog';
import content from './modules/content';
import product from './modules/product';
import guest from '@/store/modules/guest';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  state: {
    not_verified_message: 'Your email address is not verified.',
  },
  getters: {
    not_verified_message: (state) => state.not_verified_message,
  },
  mutations: {},
  actions: {},
  modules: {
    auth,
    order,
    cart,
    favorite,
    comment,
    dashboard,
    review,
    blog,
    content,
    product,
    guest,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
