import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import store from '@/store';
import router from '@/router';
import './assets/style/main.css';
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import axiosConfig from '@/utils/AxiosConfig';
import VueAgile from 'vue-agile';
import InfiniteLoading from 'v3-infinite-loading';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSidebarMenu from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import Flicking from '@egjs/vue3-flicking';
import '@egjs/vue3-flicking/dist/flicking.css';
// Or, if you have to support IE9
// Or, if you have to support IE9
// import "@egjs/vue3-flicking/dist/flicking-inline.css";
// import Cookie from 'js-cookie';

let app = createApp(App).use(store);

app.config.devtools = true;
// eslint-disable-next-line vue/multi-word-component-names
app.component('Loading', Loading);
app.component('infinite-loading', InfiniteLoading);
app
  .use(router)
  .use(axiosConfig.vueAxios, { $api: axiosConfig.api, $auth: axiosConfig.auth })
  .use(VueAgile)
  .use(VueSweetalert2)
  .use(VueSidebarMenu)
  .component('Flicking', Flicking)
  .mount('#app');
