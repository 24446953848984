import router from '@/router/index';
import {
  getError,
  toggleContentEditable,
  saveToLocalStorage,
  clearLocalStorageData,
} from '@/utils/helpers';
import OrderService from '@/services/OrderService';

export const state = {
  orders: null,
  order: null,
  customer: {},
  billingInformation: {},
  deliveryAddress: null,
  htmlFormattedAddress: null,
  isEditingBillingInformation: false,
  isEditingDeliveryAddress: false,
  meta: null,
  links: null,
  loading: false,
  error: null,
  current_status: null,
  current_page: 1,
  per_page: 10,
};

export const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders;
  },
  SET_ORDER(state, order) {
    state.order = order;
  },
  SET_CUSTOMER(state, customer) {
    state.customer = { ...customer };
  },
  SET_FORMATTED_DELIVERY_ADDRESS(state, formatted) {
    state.htmlFormattedAddress = formatted;
  },
  SET_IS_EDITING_BILLING_INFORMATION(state, isEditingBillingInformation) {
    state.isEditingBillingInformation = isEditingBillingInformation;
  },
  SET_IS_EDITING_DELIVERY_ADDRESS(state, isEditingDeliveryAddress) {
    state.isEditingDeliveryAddress = isEditingDeliveryAddress;
  },
  SET_DELIVERY_ADDRESS(state, deliveryAddress) {
    state.deliveryAddress = deliveryAddress;
  },
  SET_BILLING_INFORMATION(state, billingInformation) {
    state.billingInformation = billingInformation;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_CURRENT_PAGE: (state, current_page) => {
    state.current_page = current_page;
  },
  SET_CURRENT_STATUS: (state, current_status) => {
    state.current_status = current_status;
  },
  SET_PER_PAGE: (state, per_page) => {
    state.per_page = per_page;
  },
};

export const actions = {
  async getOrders({ commit, state }) {
    commit('SET_LOADING', true);
    try {
      const { data } = state.current_status
        ? await OrderService.getOrdersByStatus(
            state.current_status,
            state.current_page,
            state.per_page
          )
        : await OrderService.getOrders(state.current_page, state.per_page);
      const orders = data.data.map((order) => {
        return {
          id: order.id,
          ...order.attributes,
          customer: {
            id: order.relationships.customer.id,
            ...order.relationships.customer.attributes,
          },
          items: order.relationships.orderProducts.map((item) => {
            return {
              id: item.id,
              ...item.attributes,
              links: item.links,
            };
          }),
        };
      });
      commit('SET_ORDERS', {
        items: orders,
        meta: data.meta,
        total: data.total,
        per_page: data.perPage,
        last_page: data.lastPage,
        current_page: data.currentPage,
        links: data.links,
      });
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ORDERS', []);
      commit('SET_ERROR', getError(error));
    }
  },

  async getOrder({ commit }, { id }) {
    commit('SET_LOADING', true);
    try {
      const response = await OrderService.getOrder(id);
      const data = response.data;
      const order = {
        id: data.id,
        ...data.attributes,
        customer: {
          id: data.relationships.customer.id,
          ...data.relationships.customer.attributes,
        },
        invoice: {
          id: data.relationships.invoice.id,
          ...data.relationships.invoice.attributes,
        },
        items: data.relationships.orderProducts.map((item) => {
          return {
            id: item.id,
            ...item.attributes,
            category: {
              id: item.relationships.category.id,
              ...item.relationships.category.attributes,
            },
            links: item.links,
          };
        }),
      };
      commit('SET_ORDER', order);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },

  async cancelOrder({ commit }, { id }) {
    commit('SET_LOADING', true);
    try {
      await OrderService.cancelOrder(id);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },

  async confirmOrder({ commit, state, dispatch }) {
    commit('SET_LOADING');
    try {
      const payload = {
        delivery_details: {
          ...state.billingInformation,
          location: state.deliveryAddress.location ?? 'Alajo, 20', // TODO: remove empty string
          address: state.deliveryAddress.use_new_address
            ? state.deliveryAddress.new_address
            : state.deliveryAddress.current_address,
          new_address: state.deliveryAddress.new_address,
        },
      };
      await OrderService.createOrder(payload);
      commit('SET_LOADING', false);
      clearLocalStorageData([
        'billingInformation',
        'deliveryAddress',
        'orderContactInfo',
      ]);
      commit('SET_ERROR', null);
      router
        .push({ path: '/thanks' })
        .then(await dispatch('cart/emptyCart', null, { root: true }));
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ORDERS', []);
      commit('SET_ERROR', getError(error));
    }
  },
  async getEstimatedDeliveryTime({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await OrderService.getEstimatedDeliveryTime();
      const data = response.data;
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);

      return data;
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },

  formatAddressAsHtml({ commit, state }) {
    let address = '';
    let current_customer = state.customer;
    if (!Object.keys(current_customer).length) {
      const contactInfo = window.localStorage.getItem('orderContactInfo');
      const deliveryAddress = window.localStorage.getItem('deliveryAddress');
      if (!Object.keys(contactInfo).length && !Object.keys(deliveryAddress).length)
        return null;
      current_customer = JSON.parse(deliveryAddress) || JSON.parse(contactInfo);
    }
    address = !current_customer.use_new_address
      ? current_customer.current_address
      : current_customer.new_address;

    const addressArr = address.split(',');
    const formatted = addressArr.map((item) => {
      return `<p>${item}</p>`;
    });
    const billingInformation = window.localStorage.getItem('orderContactInfo') ?? {};
    commit('SET_BILLING_INFORMATION', JSON.parse(billingInformation));
    commit('SET_DELIVERY_ADDRESS', {
      current_address: current_customer.current_address,
      new_address: current_customer.new_address,
      use_new_address: current_customer.use_new_address,
    });
    commit('SET_FORMATTED_DELIVERY_ADDRESS', formatted.join(''));
  },

  toggleBillingInformation({ commit, state }, { elements }) {
    toggleContentEditable(elements, true);
    commit('SET_IS_EDITING_BILLING_INFORMATION', !state.isEditingBillingInformation);
  },

  saveBillingInformation({ commit, state }, { elements }) {
    let billingInformation = {};
    for (let value of elements) {
      billingInformation[value.dataset.key] = value.innerText;
    }
    toggleContentEditable(elements, false);
    saveToLocalStorage({
      key: 'billingInformation',
      value: billingInformation,
    });
    commit('SET_IS_EDITING_BILLING_INFORMATION', !state.isEditingBillingInformation);
    commit('SET_BILLING_INFORMATION', billingInformation);
    // send to server
  },

  toggleEditingDeliveryAddress({ commit, state }, { elements }) {
    toggleContentEditable(elements, true);
    commit('SET_IS_EDITING_DELIVERY_ADDRESS', !state.isEditingDeliveryAddress);
  },

  saveDeliveryAddress({ commit, dispatch, state }, { elements }) {
    let deliveryAddress = {};
    for (let value of elements) {
      const addressArr = value.innerText.split(/\n+/);
      deliveryAddress.new_address = addressArr.join(',');
      deliveryAddress.use_new_address = true;
    }
    toggleContentEditable(elements, false);
    saveToLocalStorage({ key: 'deliveryAddress', value: deliveryAddress });
    commit('SET_IS_EDITING_DELIVERY_ADDRESS', !state.isEditingDeliveryAddress);
    dispatch('formatAddressAsHtml');
  },

  saveOrderDetails({ commit, dispatch }, { payload }) {
    commit('SET_LOADING', true);
    const billingInformation = {
      id: payload.id,
      first_name: payload.first_name,
      last_name: payload.last_name,
      email: payload.email,
      phone: payload.phone,
    };
    const deliveryAddress = {
      current_address: payload.current_address,
      new_address: payload.new_address,
      use_new_address: payload.use_new_address,
    };

    commit('SET_BILLING_INFORMATION', billingInformation);
    saveToLocalStorage({ key: 'deliveryAddress', value: deliveryAddress });
    saveToLocalStorage({ key: 'orderContactInfo', value: payload });
    commit('SET_CUSTOMER', payload);
    dispatch('formatAddressAsHtml');
    router.push('/orders/summary').then(() => commit('SET_LOADING', false));
  },
};

export const getters = {
  orders: (state) => state.orders,
  order: (state) => state.order,
  customer: (state) => {
    if (state.customer.length) {
      return state.customer;
    }
    const storageItem = window.localStorage.getItem('orderContactInfo');
    if (!storageItem) return {};
    return JSON.parse(storageItem);
  },
  billingInformation: (state) => {
    const storageItem = window.localStorage.getItem('billingInformation');
    return storageItem && storageItem !== 'undefined'
      ? JSON.parse(storageItem)
      : state.billingInformation;
  },
  htmlFormattedAddress: (state) => state.htmlFormattedAddress,
  isEditingBillingInformation: (state) => state.isEditingBillingInformation,
  isEditingDeliveryAddress: (state) => state.isEditingDeliveryAddress,
  loading: (state) => state.loading,
  error: (state) => state.error,
  perPage: (state) => state.per_page,
  status: (state) => state.current_status,
  currentPage: (state) => state.current_page,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
