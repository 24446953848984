import AxiosConfig from '@/utils/AxiosConfig';

export default {
  async subscribeToNewsletter(payload) {
    await AxiosConfig.auth.get('/sanctum/csrf-cookie');
    return AxiosConfig.api.post('/subscribe-to-newsletter', payload);
  },
  async enquiry(payload) {
    await AxiosConfig.auth.get('/sanctum/csrf-cookie');
    return AxiosConfig.api.post('/inquiries', payload);
  },
};
