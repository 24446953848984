import axiosConfig from '@/utils/AxiosConfig';

export default {
  async getUserStats(id) {
    return axiosConfig.api.get(`/users/${id}/stats`);
  },
  async getRecentOrders(userId, limit = 5) {
    return axiosConfig.api.get(`/orders/${userId}/recent?limit=${limit}`);
  },
};
