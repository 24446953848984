/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router';
import auth from '@/middleware/auth';
// import admin from '@/middleware/admin';
import guest from '@/middleware/guest';
import hasCart from '@/middleware/hasCart';
import middlewarePipeline from '@/router/middlewarePipeline';
import store from '@/store/index.js';
import verified from '@/middleware/verified';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: 'home' */ '@/views/Home'),
  },
  {
    path: '/#services',
    name: 'Services',
    component: () => import(/* webpackChunkName: 'home' */ '@/views/Home'),
  },
  {
    path: '/services/:id',
    name: 'ViewService',
    component: () => import(/* webpackChunkName: 'view-service' */ '@/views/ViewService'),
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import(/* webpackChunkName: 'products' */ '@/views/products/Products'),
  },
  {
    path: '/menu/:id',
    name: 'ViewMenu',
    component: () => import(/* webpackChunkName: 'view-product' */ '@/views/products/ViewProduct'),
  },
  {
    path: '/menu/categories/:category',
    name: 'CategoryProducts',
    component: () => import(/* webpackChunkName: 'category-products' */ '@/views/products/CategoryProducts'),
  },
  {
    path: '/blog',
    name: 'Blog',
    meta: {
      breadCrumbs: [{
        to: '/blog', // hyperlink
        text: 'Blog' // crumb text
      }]
    },
    component: () => import(/* webpackChunkName: 'blog' */ '@/views/blog/Blog'),
    props: (route) => ({ page: route.query.page ?? '1' }),
  },
  {
    path: '/blog/post/:id',
    name: 'BlogPost',
    meta: {
      breadCrumbs: [{
        to: '/blog/post/:id', // hyperlink
        text: (route) => route.params.id // crumb text
      }]
    },
    component: () => import(/* webpackChunkName: 'blog-post' */ '@/views/blog/BlogPost'),
    props: (route) => ({ id: route.params.id, page: route.query.page ?? '1' }),
  },
  {
    path: '/blog/tag/:tag',
    name: 'BlogTag',
    meta: {
      breadCrumbs: [{
        to: '/blog/tag/:tag', // hyperlink
        text: (route) => route.params.tag // crumb text
      }]
    },
    component: () => import(/* webpackChunkName: 'blog-tag' */ '@/views/blog/BlogTag'),
    props: (route) => ({ tag: route.params.tag, page: route.query.page ?? '1' }),
  },
  {
    path: '/blog/tag/:tag/post/:id',
    name: 'TagBlogPost',
    meta: {
      breadCrumbs: [{
        to: '/blog/tag/:tag/post/:id', // hyperlink
        text: (route) => route.params.id // crumb text
      }]
    },
    component: () => import(/* webpackChunkName: 'tag-blog-post' */ '@/views/blog/BlogPost'),
    props: (route) => ({ tag: route.params.tag, id: route.params.id, page: route.query.page ?? '1' }),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: 'login' */ '@/views/auth/Login'),
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    meta: { middleware: [auth] },
    component: () => import(/* webpackChunkName: 'login' */ '@/views/auth/VerifyEmail'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: 'login' */ '@/views/password/ForgotPassword'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: 'login' */ '@/views/password/ResetPassword'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { middleware: [auth, verified] },
    component: () => import(/* webpackChunkName: 'dashboard' */ '@/views/customer/Home'),
  },
  {
    path: '/register',
    name: 'Register',
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: 'register' */ '@/views/auth/Register'),
  },
  {
    path: '/thanks',
    name: 'Thanks',
    meta: { middleware: [auth, verified] },
    component: () => import(/* webpackChunkName: 'thanks' */ '@/views/Thanks'),
  },
  {
    path: '/checkout',
    name: 'Checkout',
    meta: { middleware: [hasCart, auth, verified] },
    component: () => import(/* webpackChunkName: 'checkout' */ '@/views/Checkout'),
  },
  {
    path: '/orders/summary',
    name: 'ConfirmOrder',
    meta: { middleware: [hasCart, auth, verified] },
    component: () => import(/* webpackChunkName: 'order-summary' */ '@/views/ConfirmOrder'),
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: { middleware: [auth, verified] },
    component: () => import(/* webpackChunkName: 'pending-orders' */ '@/views/customer/orders/Index'),
    props: (route) => ({ status: route.query.status, perPage: route.query.perPage, page: route.query.page ?? '1' }),
  },
  {
    path: '/orders/:id',
    name: 'ViewOrder',
    meta: { middleware: [auth, verified] },
    component: () => import(/* webpackChunkName: 'view-order' */ '@/views/customer/orders/Order'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: 'about' */ '@/views/About'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { middleware: [auth, verified] },
    component: () => import(/* webpackChunkName: 'profile' */ '@/views/customer/profile/Profile'),
  },
  {
    path: '/favorites',
    name: 'Favorites',
    meta: { middleware: [auth, verified] },
    component: () => import(/* webpackChunkName: 'favorites' */ '@/views/customer/products/favorites/Index'),
  },
  {
    path: '/reviews',
    name: 'Reviews',
    meta: { middleware: [auth, verified] },
    component: () => import(/* webpackChunkName: 'reviews' */ '@/views/customer/products/reviews/Index'),
  },
  {
    path: '/reviews/:id',
    name: 'ReviewView',
    meta: { middleware: [auth, verified] },
    component: () => import(/* webpackChunkName: 'view-review' */ '@/views/customer/products/reviews/ReviewView'),
  },
  {
    path: '/reviews/:id/edit',
    name: 'EditReview',
    meta: { middleware: [auth, verified] },
    component: () => import(/* webpackChunkName: 'edit-review' */ '@/views/customer/products/reviews/EditReview'),
  },
  {
    path: '/comments',
    name: 'Comments',
    meta: { middleware: [auth, verified] },
    component: () => import(/* webpackChunkName: 'comments' */ '@/views/customer/blog/Comments'),
  },
  {
    path: '/comments/:id',
    name: 'CommentView',
    meta: { middleware: [auth, verified] },
    component: () => import(/* webpackChunkName: 'view-comment' */ '@/views/customer/blog/CommentView'),
  },
  {
    path: '/comments/:id/edit',
    name: 'EditComment',
    meta: { middleware: [auth, verified] },
    component: () => import(/* webpackChunkName: 'edit-comment' */ '@/views/customer/blog/EditComment'),
  },
  {
    path: '/demo',
    name: 'Demo',
    component: () => import(/* webpackChunkName: 'demo' */ '@/views/Demo'),
  },
  {
    path: '/:catchAll(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: '404' */ '@/views/404'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
    // store.state.loading = true;
    if (store.getters['auth/authUser'] && store.getters['cart/cart']) {
      /** Navigate to next if middleware is not applied */
      if (!to.meta.middleware) {
        return next()
      }
      const middleware = to.meta.middleware;
      const context = {
        to,
        from,
        next,
        store, //     | You can also pass store as an argument
        router, //    | to the middleware
      }
      return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
      });
    } else {
    store.dispatch('auth/getAuthUser').then(() => {
      store.dispatch('cart/getCart').then(() => {
        /** Navigate to next if middleware is not applied */
        if (!to.meta.middleware) {
          return next()
        }
        const middleware = to.meta.middleware;
        const context = {
          to,
          from,
          next,
          store, //     | You can also pass store as an argument
          router, //    | to the middleware
        }
        return middleware[0]({
          ...context,
          next: middlewarePipeline(context, middleware, 1)
        });
      }).catch((error) => {
        console.error(`Get Cart Error from router: ${error}`);
      });
    })
    .catch((error) => {
      console.error(`Get Auth User Error from router: ${error}`);
    });
  }
});

export default router;
