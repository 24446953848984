import { getError } from '@/utils/helpers';
import DashboardService from '@/services/DashboardService';

export const state = {
  stats: null,
  recentOrders: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_STATS(state, stats) {
    state.stats = stats;
  },
  SET_RECENT_ORDERS(state, recentOrders) {
    state.recentOrders = recentOrders;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  UPDATE_PRODUCT_QUANTITY(state, quantity) {
    state.stats.orders.most_ordered.quantity = quantity;
  },
};

export const actions = {
  async getStats({ commit }, { id }) {
    commit('SET_LOADING', true);

    try {
      const response = await DashboardService.getUserStats(id);
      const data = response.data.data;
      commit('SET_STATS', data);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },

  async getRecentOrders({ commit }, { id, limit }) {
    commit('SET_LOADING', true);
    try {
      console.log(id, 'here');

      const response = await DashboardService.getRecentOrders(id, limit);
      const data = response.data;
      const items = data.data.map((order) => {
        return {
          id: order.id,
          ...order.attributes,
          customer: {
            id: order.relationships.customer.id,
            ...order.relationships.customer.attributes,
          },
          items: order.relationships.orderProducts.map((item) => {
            return {
              id: item.id,
              ...item.attributes,
              // links: item.links,
            };
          }),
        };
      });
      commit('SET_RECENT_ORDERS', { items: items, meta: data.meta });
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_RECENT_ORDERS', []);
      commit('SET_ERROR', getError(error));
    }
  },
};

export const getters = {
  stats: (state) => state.stats,
  recentOrders: (state) => state.recentOrders,
  loading: (state) => state.loading,
  product_quantity: (state) => state.stats.orders.most_ordered.quantity,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
