import axiosConfig from '@/utils/AxiosConfig';

export default {
  async getUserReviews(id, status = null, page = 1, perPage = 10) {
    if (status?.toLowerCase() === 'all' || status === null) {
      return axiosConfig.api.get(`/users/${id}/reviews?page=${page}&perPage=${perPage}`);
    }
    return axiosConfig.api.get(
      `/users/${id}/reviews?status=${status}&page=${page}&perPage=${perPage}`
    );
  },
  async getUserReview(id, reviewId) {
    return axiosConfig.api.get(`/users/${id}/reviews/${reviewId}`);
  },
  async deleteReview(id, reviewId) {
    return axiosConfig.api.delete(`/users/${id}/reviews/${reviewId}`);
  },
  async updateReview(id, reviewId, payload) {
    return axiosConfig.api.put(`/users/${id}/reviews/${reviewId}/`, payload);
  },
  async store(payload) {
    return axiosConfig.api.post('/products/reviews', payload);
  },
  async toggleLike(payload) {
    return axiosConfig.api.post('/products/reviews/like', payload);
  },
  constructSimpleReview(review) {
    return {
      id: review.id,
      ...review.attributes,
      author: {
        id: review.relationships.author.id,
        ...review.relationships.author.attributes,
      },
    };
  },
};
