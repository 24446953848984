<template>
  <router-view></router-view>
  <!-- <vue-ins-progress-bar></vue-ins-progress-bar> -->
</template>
<script>
export default {
  name: 'App',
  // mounted() {
  //   this.$insProgress.finish();
  // },
  // created() {
  //   this.$insProgress.start();

  //   this.$router.beforeEach((to, from, next) => {
  //     this.$insProgress.start();
  //     next();
  //   });

  //   this.$router.afterEach((/*to, from*/) => {
  //     this.$insProgress.finish();
  //   });
  // },
};
</script>
<style>
.bg-yellow-400 {
  background-color: #f5f502;
}
.c-bg-gray-800 {
  background-color: #282811;
}
.text-yellow-400 {
  color: #f5f502;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.default {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
