import GuestService from '@/services/GuestService';
import { getError } from '@/utils/helpers';

export default {
  namespaced: true,
  state: {
    loading: false,
    error: null,
    newsletter_payload: {
      email: null,
      first_name: null,
      last_name: null,
    },
    enquiry_payload: {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      message: null,
    },
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_ERROR(state, value) {
      state.error = value;
    },
    SET_NEWSLETTER_PAYLOAD(state, { label, value }) {
      state.newsletter_payload[label] = value;
    },
    SET_ENQUIRY_PAYLOAD(state, { label, value }) {
      state.enquiry_payload[label] = value;
    },
  },
  actions: {
    async sendEnquiry({ commit, state }) {
      commit('SET_LOADING', true);
      try {
        await GuestService.enquiry(state.enquiry_payload);
        commit('SET_ERROR', null);
      } catch (error) {
        commit('SET_ERROR', getError(error));
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async subscribeToNewsletter({ commit, dispatch, state }) {
      commit('SET_LOADING', true);
      try {
        const { data } = await GuestService.subscribeToNewsletter(
          state.newsletter_payload
        );
        dispatch(
          'auth/updateNewsletterSubscriberStatus',
          { value: data.has_subscribed },
          { root: true }
        ).then(() => {
          commit('SET_ERROR', null);
        });
      } catch (error) {
        commit('SET_ERROR', getError(error));
      } finally {
        commit('SET_LOADING', false);
      }
    },
  },
  getters: {
    newsletterPayload: (state) => state.newsletter_payload,
    enquiryPayload: (state) => state.enquiry_payload,
    isLoading: (state) => state.loading,
    error: (state) => state.error,
  },
};
