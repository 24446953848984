import router from '@/router';
export const getError = (error) => {
  const singleErrorMessageCode = [400, 401, 403, 404, 409, 500];
  const { response } = error;
  const { request } = error;
  // const { message } = error;
  // const { config } = error;
  const defaultErrorMessage = 'API Error, please try again.';
  if (error.name === 'Fetch User') {
    return error.message;
  }

  if (!response && !request) {
    console.error(`API ${error} not found`);
    return defaultErrorMessage;
  }
  if (response) {
    const errorCode = response.status;
    if (process.env.NODE_ENV === 'development') {
      console.error(response.data);
      console.error(response.status);
      console.error(response.headers);
    }
    if (singleErrorMessageCode.includes(errorCode)) {
      if (errorCode === 401 || errorCode === 403) {
        return response.data.message;
      }
      if (errorCode === 404) {
        router.push('/404');
        return;
      }
      if (errorCode === 409) {
        console.log(response.data.message);
        return response.data.message;
      }

      if (errorCode === 500) {
        return 'Server error, please contact the admin';
      }
      return response.data.error.message;
    }
    if (response.data && response.data.errors) {
      return response.data.message;
    }
  }
  if (request) {
    if (process.env.NODE_ENV === 'development') {
      console.error(response.data);
      console.error(response.status);
      console.error(response.headers);
    }
  }

  return defaultErrorMessage;
};
/**
 * Toggle editable attribute to element and classes
 *
 * @param {object} elements The html element to add the editable attribute to
 * @param {bool} toggle default true, if false remove the class & editable attribute
 *
 * @return {void}
 */
export const toggleContentEditable = (elements, toggle) => {
  const classesToToggle = [
    'bg-yellow-100',
    'p-2',
    'mb-2',
    'border-2',
    'border-yellow-300',
    'rounded-md',
  ];
  for (let value of elements) {
    value.setAttribute('ContentEditable', toggle);
    if (toggle) {
      value.classList.add(...classesToToggle);
    } else {
      value.classList.remove(...classesToToggle);
    }
  }
};

/**
 * Check if a given object is empty
 *
 * @param {object} value The object to check
 *
 * @return {bool} true if the object is empty, false otherwise
 */
export const isObjectEmpty = (value) => {
  return Object.keys(value).length === 0;
};

/**
 * Save value to localStorage
 *
 * @@param {objex} data a key/value object key is string and value is any
 *
 * @return {void}
 */
export const saveToLocalStorage = (data) => {
  try {
    window.localStorage.setItem(data.key, JSON.stringify(data.value));
  } catch (error) {
    throw Error(error);
  }
};

export const clearLocalStorageData = (...data) => {
  try {
    if (data instanceof Array) {
      data.map((value) => {
        window.localStorage.removeItem(value);
      });

      return true;
    }

    return window.localStorage.removeItem(data);
  } catch (error) {
    throw Error(error);
  }
};

export const cleanDate = (dateString) => {
  try {
    if (typeof dateString === 'string')
      return dateString.replace('T', ' ').replace('.000000Z', '');
    throw Error(
      'TypeError: The function cleanDate expect a string ' + typeof dateString + 'found!'
    );
  } catch (error) {
    throw Error(error);
  }
};
