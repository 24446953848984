import ProductService from '@/services/ProductService';
import { getError } from '@/utils/helpers';

/**
 * Product store
 */
const state = {
  all: null,
  single: null,
  related: null,
  best_selling: null,
  featured: null,
  popular: null,
  /**
   * Parent categories
   */
  categories: null,
  /**
   * Parent categories with subcategories
   */
  categories_all: null,
  category: null,
  current_category: null,
  category_products: [],
  loading: false,
  error: null,
};
const mutations = {
  /**
   * Sets the list of all products.
   *
   * @param {object} state - The Vuex state object.
   * @param {array} all - The list of all products.
   */
  SET_ALL(state, all) {
    state.all = all;
  },

  /**
   * Sets the single product in the state.
   *
   * @param {object} state - The Vuex state object.
   * @param {object} single - The single product to be set.
   */
  SET_SINGLE(state, single) {
    state.single = single;
  },

  /**
   * Sets the related products in the state.
   *
   * @param {object} state - The Vuex state object.
   * @param {array} related - The list of related products to be set.
   */
  SET_RELATED(state, related) {
    state.related = related;
  },
  /**
   * Sets the best selling products in the state.
   *
   * @param {object} state - The Vuex state object.
   * @param {array} best_selling - The list of best selling products to be set.
   */
  SET_BEST_SELLING(state, best_selling) {
    state.best_selling = best_selling;
  },
  /**
   * Sets the featured products in the state.
   *
   * @param {object} state - The Vuex state object.
   * @param {array} featured - The list of featured products to be set.
   */
  SET_FEATURED(state, featured) {
    state.featured = featured;
  },
  /**
   * Sets the popular products in the state.
   *
   * @param {object} state - The Vuex state object.
   * @param {array} popular - The list of popular products to be set.
   */
  SET_POPULAR(state, popular) {
    state.popular = popular;
  },
  /**
   * Sets the categories in the state.
   *
   * @param {object} state - The Vuex state object.
   * @param {array} categories - The list of categories to be set.
   */
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  /**
   * Sets all categories in the state.
   *
   * @param {object} state - The Vuex state object.
   * @param {array} categories_all - The complete list of categories to be set.
   */
  SET_CATEGORIES_ALL(state, categories_all) {
    state.categories_all = categories_all;
  },
  /**
   * Sets the loading state in the product module.
   *
   * @param {object} state - The Vuex state object.
   * @param {boolean} loading - The loading state to be set.
   */
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  /**
   * Sets the current category in the product module.
   *
   * @param {object} state - The Vuex state object.
   * @param {object} current_category - The current category to be set.
   */
  SET_CURRENT_CATEGORY(state, current_category) {
    state.current_category = current_category;
  },
  /**
   * Sets the category in the state.
   *
   * @param {object} state - The Vuex state object.
   * @param {object} category - The category to be set.
   */
  SET_CATEGORY(state, category) {
    state.category = category;
  },
  UPDATE_PRODUCT_QUANTITY(
    state,
    { index = null, quantity, type = 'all', category = null }
  ) {
    switch (type) {
      case 'single':
        state.single.quantity = Number(quantity);
        break;
      case 'best_selling':
        state.best_selling.quantity = Number(quantity);
        break;
      case 'all':
        state.all[category][index].quantity = Number(quantity);
        break;
      case 'related':
        state.related[index].quantity = Number(quantity);
        break;
      case 'popular':
        state.popular[index].quantity = Number(quantity);
        break;
      case 'featured':
        state.featured[index].quantity = Number(quantity);
        break;
      default:
        throw new Error(
          "Invalid type. Expected 'all', 'single', 'related', 'popular', 'best_selling' or 'featured'."
        );
    }
  },
  UPDATE_PRODUCT_FAVORITE_STATUS(
    state,
    { index = null, status, type = 'all', category = null }
  ) {
    switch (type) {
      case 'single':
        state.single.favoured = status;
        break;
      case 'best_selling':
        state.best_selling.favoured = status;
        break;
      case 'all':
        state.all[category][index].favoured = status;
        break;
      case 'related':
        state.related[index].favoured = status;
        break;
      case 'popular':
        state.popular[index].favoured = status;
        break;
      case 'featured':
        state.featured[index].favoured = status;
        break;
      default:
        throw new Error(
          "Invalid type. Expected 'all', 'single', 'related', 'popular', 'best_selling' or 'featured'."
        );
    }
  },
  SET_PRODUCT_CATEGORY(state, { id, products }) {
    state.category_products[id] = products;
  },
  /**
   * Sets the error state in the product module.
   *
   * @param {object} state - The Vuex state object.
   * @param {Error} error - The error to be set.
   */
  SET_ERROR(state, error) {
    state.error = error;
  },
  UPDATE_PRODUCT_REVIEW_LIKE_STATUS(state, index) {
    const review = state.single.reviews[index];
    if (!review.userLiked) {
      review.userLiked = true;
      review.reactionsCount += 1;
    } else {
      review.userLiked = false;
      review.reactionsCount -= 1;
    }
    state.single.reviews[index] = review;
  },
  UPDATE_PRODUCT_REVIEWS(state, { review, product }) {
    state.single.reviews.unshift(review);
    state.single.reviewsCount = product.attributes.reviewsCount;
    state.single.rating = product.attributes.rating;
  },
};
const actions = {
  /**
   * Retrieves a list of all categories.
   *
   * @param {object} { commit } - Vuex commit function.
   * @returns {Promise<void>} - Resolves when the action is complete.
   * @param query
   */
  async getCategories({ commit }, { query } = null) {
    commit('SET_LOADING', true);
    try {
      const { data } = await ProductService.getCategories(query ?? '');
      const categories = data.data.map((category) => {
        return {
          id: category.id,
          ...category.attributes,
        };
      });
      commit('SET_CURRENT_CATEGORY', categories[0]);
      commit('SET_CATEGORIES', categories);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_ERROR', getError(error));
    } finally {
      commit('SET_LOADING', false);
    }
  },
  /**
   * Retrieves a category by its id.
   *
   * @param {object} context - The Vuex context object.
   * @param {object} payload - The payload object.
   * @param {number} payload.id - The id of the category to be retrieved.
   *
   * @returns {Promise<void>} - Resolves when the action is complete.
   */
  async getCategory({ commit }, { id }) {
    commit('SET_LOADING', true);
    commit('SET_CATEGORY', null);
    try {
      const { data } = await ProductService.getCategory(id);
      const category = {
        id: data.id,
        ...data.attributes,
      };
      commit('SET_CATEGORY', category);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_ERROR', getError(error));
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getProducts({ commit, state }, { id, query }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await ProductService.getProductsByCategory(id, query);
      const products = data.data.map((product) =>
        query === undefined
          ? ProductService.constructProduct(product)
          : product.map((p) => ProductService.constructProduct(p))
      );
      commit('SET_PRODUCT_CATEGORY', { id: id, products: products });
      commit('SET_ALL', state.category_products);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_ERROR', getError(error));
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getRelatedProducts({ commit, state }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await ProductService.getRelatedProducts(state.single.id);
      const relatedProducts = data.data.map((product) =>
        ProductService.constructProduct(product)
      );
      commit('SET_RELATED', relatedProducts);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_ERROR', getError(error));
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getProduct({ commit }, { id }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await ProductService.getProduct(id);
      const product = ProductService.constructProduct(data);
      commit('SET_SINGLE', product);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_ERROR', getError(error));
    } finally {
      commit('SET_LOADING', false);
    }
  },
  /**
   * Retrieves a list of popular products. If a category is set, it will retrieve
   * the popular products for that category.
   *
   * @param {object} { commit, state } - Vuex commit and state objects.
   * @returns {Promise<void>} - Resolves when the action is complete.
   */
  async getPopular({ commit, state }) {
    commit('SET_LOADING', true);
    try {
      const { data } = state.current_category
        ? await ProductService.getPopularProductsByCategory(state.current_category.id)
        : await ProductService.getPopularProducts();
      const products = data.data.popular.map((product) =>
        ProductService.constructProduct(product)
      );
      const best_selling = ProductService.constructProduct(data.data.bestSelling);
      commit('SET_BEST_SELLING', best_selling);
      commit('SET_POPULAR', products);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_ERROR', getError(error));
    } finally {
      commit('SET_LOADING', false);
    }
  },
  /**
   * Retrieves the best-selling products. If a category is set, it will retrieve
   * the best-selling products for that category.
   *
   * @param {object} { commit, state } - Vuex commit and state objects.
   * @returns {Promise<void>} - Resolves when the action is complete.
   */
  async getBestSelling({ commit, state }) {
    commit('SET_LOADING', true);
    try {
      const { data } = state.current_category
        ? await ProductService.getBestSellingProductsByCategory(state.current_category.id)
        : await ProductService.getBestSellingProducts();
      const best_selling = ProductService.constructProduct(data);
      commit('SET_BEST_SELLING', best_selling);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_ERROR', getError(error));
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getFeatured({ commit }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await ProductService.getFeaturedProducts();
      const featured = data.data.map((product) =>
        ProductService.constructProduct(product)
      );
      commit('SET_FEATURED', featured);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_ERROR', getError(error));
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateProductReviews({ commit }, { review, product }) {
    try {
      commit('UPDATE_PRODUCT_REVIEWS', { review, product });
    } catch (error) {
      commit('review/SET_ERROR', error, { root: true });
    }
  },
  async toggleReviewLike({ commit }, { index }) {
    try {
      commit('UPDATE_PRODUCT_REVIEW_LIKE_STATUS', index);
    } catch (error) {
      commit('review/SET_ERROR', error, { root: true });
    }
  },
};
const getters = {
  all: (state) => (index) => state.all ? state.all[index] : null,
  single: (state) => state.single,
  related: (state) => state.related,
  best_selling: (state) => state.best_selling,
  featured: (state) => state.featured,
  popular: (state) => state.popular,
  categories: (state) => state.categories,
  categories_all: (state) => state.categories_all,
  current_category: (state) => state.current_category,
  category: (state) => state.category,
  loading: (state) => state.loading,
  error: (state) => state.error,
  product_quantity: (state) =>
    function (index, type, category_index) {
      switch (type) {
        case 'single':
          return state.single.quantity;
        case 'best_selling':
          return state.best_selling.quantity;
        case 'all':
          return state.all[category_index][index].quantity;
        case 'related':
          return state.related[index].quantity;
        case 'popular':
          return state.popular[index].quantity;
        case 'featured':
          return state.featured[index].quantity;
        default:
          throw new Error(
            "Invalid type. Expected 'all', 'single', 'related', 'popular', 'best_selling' or 'featured'."
          );
      }
    },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
