import axiosConfig from '@/utils/AxiosConfig';

export default {
  async getItems(id) {
    return axiosConfig.api.get(`/users/${id}/favorites`);
  },
  async getItem(id, userId) {
    return axiosConfig.api.get(`/users/${userId}/favorites/${id}`);
  },
  async addItem(payload) {
    return axiosConfig.api.post(`/products/favorites`, payload);
  },
};
