import { getError } from '@/utils/helpers';
import FavoriteService from '@/services/FavoriteService';
import router from '@/router';

export const state = {
  favorites: null,
  favorite: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_FAVORITES(state, favorites) {
    state.favorites = favorites;
  },
  SET_FAVORITE(state, favorite) {
    state.favorite = favorite;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  DELETE_FAVORITE(state, index) {
    state.favorites.splice(index, 1);
  },
};

export const actions = {
  async getItems({ commit }, { id }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await FavoriteService.getItems(id);
      const items = data.data.map((item) => {
        return {
          id: item.id,
          ...item.attributes,
          category: {
            id: item.relationships.category.id,
            ...item.relationships.category.attributes,
          },
          ingredients: {
            ...item.relationships.ingredients.data.map(
              (ingredient) => ingredient.attributes.name
            ),
          },
        };
      });
      commit('SET_FAVORITES', items);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async getItem({ commit }, { id }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await FavoriteService.getItem(id);
      const item = {
        id: data.id,
        ...data.attributes,
      };
      commit('SET_FAVORITE', item);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async addItem({ commit }, { id }) {
    try {
      commit('SET_LOADING', true);
      await FavoriteService.addItem({ product_id: id });
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async removeItem({ commit }, { index, id, from_table = true }) {
    if (confirm('Are you sure you want to remove this product from favorites')) {
      commit('SET_LOADING', true);
      try {
        await FavoriteService.addItem({ product_id: id });
        if (router.currentRoute._rawValue.path !== '/favorites' && !from_table) {
          router.push({ name: 'Reviews', params: {} }).then();
          return;
        }
        if (index >= 0 && index !== null) {
          commit('DELETE_FAVORITE', index);
        }
        commit('SET_LOADING', false);
        commit('SET_ERROR', null);
      } catch (error) {
        commit('SET_LOADING', false);
        commit('SET_ERROR', getError(error));
      }
    }
  },
};

export const getters = {
  favorites: (state) => {
    return state.favorites;
  },
  favorite: (state) => {
    return state.favorite;
  },
  error: (state) => {
    return state.error;
  },
  loading: (state) => {
    return state.loading;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
