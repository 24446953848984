import axiosConfig from '@/utils/AxiosConfig';

export default {
  getPage(page) {
    return axiosConfig.api.get(`/pages/${page}`);
  },
  getServices() {
    return axiosConfig.api.get('/content/services');
  },
  getService(id) {
    return axiosConfig.api.get(`/content/services/${id}`);
  },
  getCustomersQuote() {
    return axiosConfig.api.get('/content/quotes');
  },
  getTeam() {
    return axiosConfig.api.get('/content/team');
  },
  getPrivacyPolicy() {
    return axiosConfig.api.get('/content/privacy-policy');
  },
  getTermsAndConditions() {
    return axiosConfig.api.get('/content/terms-and-conditions');
  },
};
