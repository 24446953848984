import axios from 'axios';
import axios2 from 'axios';
import VueAxios from 'vue-axios';
import store from '@/store';
// const API_ROOT_URL = process.env.VUE_APP_API_ROOT_URL;
// const API_BASE_PATH = process.env.VUE_APP_API_BASE_PATH;
// const API_PORT = process.env.VUE_APP_API_PORT;
// const API_VERSION = process.env.VUE_APP_API_VERSION;

// const API_BASE_URL = API_ROOT_URL + ':' + API_PORT;
// const API_FULL = API_ROOT_URL + ':' + API_PORT;
// const API_FULL_URL = API_FULL + API_BASE_PATH + API_VERSION;

const COMMON_HEADERS = {
  common: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};
let Auth = axios.create({
  baseURL: 'https://api-larica.ekangdev.com',
  withCredentials: true,
  headers: COMMON_HEADERS,
});
let Api = axios2.create({
  baseURL: 'https://api-larica.ekangdev.com/api/v1/',
  withCredentials: true,
  headers: COMMON_HEADERS,
});
Api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      store.getters['auth/authUser'] &&
      !store.getters['auth/guest']
    ) {
      store.dispatch('auth/logout').then((r) => console.log(r));
    }
    return Promise.reject(error);
  }
);
Auth.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      store.getters['auth/authUser'] &&
      !store.getters['auth/guest']
    ) {
      store.dispatch('auth/logout').then((r) => console.log(r));
    }
    return Promise.reject(error);
  }
);

export default {
  vueAxios: VueAxios,
  auth: Auth,
  api: Api,
};
