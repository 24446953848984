import BlogService from '@/services/BlogService';
import { getError } from '@/utils/helpers';

const state = {
  articles: null,
  article: null,
  popular_articles: null,
  popular_article: null,
  latest_articles: null,
  current_article_id: null,
  tags: null,
  current_tag: null,
  current_page: 1,
  loading: false,
  error: null,
};
const getters = {
  articles: (state) => state.articles,
  article: (state) => state.article,
  popular_articles: (state) => state.popular_articles,
  popular_article: (state) => state.popular_article,
  latest_articles: (state) => state.latest_articles,
  tags: (state) => state.tags,
  current_tag: (state) => state.current_tag,
  loading: (state) => state.loading,
  error: (state) => state.error,
};
const mutations = {
  SET_ARTICLES: (state, articles) => {
    state.articles = articles;
  },
  SET_ARTICLE: (state, article) => {
    state.article = article;
  },
  SET_POPULAR_ARTICLES: (state, popular_articles) => {
    state.popular_articles = popular_articles;
  },
  SET_POPULAR_ARTICLE: (state, popular_article) => {
    state.popular_article = popular_article;
  },
  SET_LATEST_ARTICLES: (state, latest_articles) => {
    state.latest_articles = latest_articles;
  },
  SET_TAGS: (state, tags) => {
    state.tags = tags;
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_CURRENT_TAG: (state, current_tag) => {
    state.current_tag = current_tag;
  },
  SET_CURRENT_ARTICLE_ID: (state, current_article_id) => {
    state.current_article_id = current_article_id;
  },
  SET_CURRENT_PAGE: (state, current_page) => {
    state.current_page = current_page;
  },
  UPDATE_ARTICLE_COMMENT_LIKE_STATUS(state, index) {
    const comment = state.article.comments[index];
    comment.userLiked = !comment.userLiked;
    comment.reactionsCount += comment.userLiked ? 1 : -1;
    state.article.comments.splice(index, 1, comment);
  },
  UPDATE_ARTICLE_COMMENTS(state, { comment, article }) {
    state.article.comments.unshift(comment);
    state.article.commentsCount = article.attributes.commentsCount;
  },
  UPDATE_ARTICLE_REACTIONS_COUNT(state) {
    state.article.userLiked = !state.article.userLiked;
    state.article.reactionsCount += state.article.userLiked ? 1 : -1;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};
const actions = {
  async getArticles({ commit, state }) {
    commit('SET_LOADING', true);
    try {
      const { data } = state.current_tag
        ? await BlogService.getArticlesByTag(state.current_tag, state.current_page)
        : await BlogService.getArticles(state.current_page);
      const articles = data.data.map((post) => {
        return {
          id: post.id,
          ...post.attributes,
          author: post.relationships.author.attributes,
          tags: post.relationships.tags
            ? post.relationships.tags.map((tag) => {
                return { ...tag.attributes };
              })
            : null,
          meta: post.meta,
        };
      });
      const blog_articles = {
        articles: articles,
        total: data.total,
        per_page: data.perPage,
        last_page: data.lastPage,
        current_page: data.currentPage,
        links: data.links,
      };

      commit('SET_ARTICLES', blog_articles);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async getArticle({ commit, state }) {
    commit('SET_LOADING', true);
    try {
      const { data } = state.current_tag
        ? await BlogService.getArticleByTag(state.current_tag, state.current_article_id)
        : await BlogService.getArticle(state.current_article_id);
      const article = {
        id: data.id,
        ...data.attributes,
        author: data.relationships.author.attributes,
        tags: data.relationships.tags
          ? data.relationships.tags.map((tag) => {
              return { ...tag.attributes };
            })
          : null,
        comments: data.relationships.comments.map((comment) => {
          return {
            id: comment.id,
            ...comment.attributes,
            author: {
              id: comment.relationships.author.id,
              ...comment.relationships.author.attributes,
            },
          };
        }),
        links: data.links,
        meta: data.meta,
      };
      commit('SET_ARTICLE', article);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async getLatestArticles({ commit, state }) {
    commit('SET_LOADING', true);
    commit('SET_LATEST_ARTICLES', null);
    try {
      const { data } = state.current_tag
        ? await BlogService.getLatestArticlesByTag(state.current_tag)
        : await BlogService.getLatestArticles();
      const latest_articles = data.data.map((article) => {
        return {
          id: article.id,
          ...article.attributes,
          author: article.relationships.author.attributes,
          tags: article.relationships.tags
            ? article.relationships.tags.map((tag) => {
                return { ...tag.attributes };
              })
            : null,
          meta: article.meta,
        };
      });
      commit('SET_LATEST_ARTICLES', latest_articles);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      console.log(error);

      commit('SET_ERROR', getError(error));
    }
  },
  async getPopularArticles({ commit, state }) {
    commit('SET_LOADING', true);
    try {
      const { data } = state.current_tag
        ? await BlogService.getPopularArticlesByTag(
            state.current_tag,
            state.current_article_id
          )
        : await BlogService.getPopularArticles(state.current_article_id);
      const popular_articles = data.data.popular.map((article) => {
        return BlogService.constructArticle(article);
      });
      const popular_article = BlogService.constructArticle(data.data.topArticle);
      commit('SET_POPULAR_ARTICLE', popular_article);
      commit('SET_POPULAR_ARTICLES', popular_articles);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async getPopularArticle({ commit, state }) {
    commit('SET_LOADING', true);
    commit('SET_POPULAR_ARTICLE', null);
    try {
      const { data } = state.current_tag
        ? await BlogService.getPopularArticleByTag(state.current_tag)
        : await BlogService.getPopularArticle();
      const popular_article = BlogService.constructArticle(data);
      commit('SET_POPULAR_ARTICLE', popular_article);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async getTags({ commit }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await BlogService.getTags();
      const tags = data.data.map((tag) => {
        return {
          id: tag.id,
          ...tag.attributes,
        };
      });
      commit('SET_TAGS', tags);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async updateArticleComments({ commit }, { comment, article }) {
    try {
      commit('UPDATE_ARTICLE_COMMENTS', { comment, article });
    } catch (error) {
      commit('comment/SET_ERROR', error, { root: true });
    }
  },
  async updateArticleCommentsCount({ commit }, { index }) {
    try {
      commit('UPDATE_ARTICLE_COMMENT_LIKE_STATUS', index);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('comment/SET_ERROR', error, { root: true });
    }
  },
  async toggleLike({ commit }, { payload }) {
    try {
      const { data } = await BlogService.toggleLike(payload);

      commit('UPDATE_ARTICLE_REACTIONS_COUNT');
      commit('SET_ERROR', null);
      return data;
    } catch (error) {
      commit('SET_ERROR', getError(error));
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
