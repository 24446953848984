import { getError } from '@/utils/helpers';
import ReviewService from '@/services/ReviewService';
import router from '@/router';

export const state = {
  reviews: null,
  review: null,
  loading: false,
  error: null,
  current_page: 1,
  current_status: null,
  per_page: 10,
};

export const mutations = {
  SET_REVIEWS(state, reviews) {
    state.reviews = reviews;
  },
  SET_REVIEW(state, review) {
    state.review = review;
  },
  UPDATE_REVIEW_PROPERTY(state, { value, property }) {
    switch (property) {
      case 'title':
        state.review.title = value;
        break;
      case 'content':
        state.review.content = value;
        break;
      case 'rating':
        state.review.rating = value;
        break;
      default:
        throw new Error('Invalid property type');
    }
  },
  DELETE_REVIEW(state, id) {
    state.reviews = state.reviews.filter((item) => item.id !== id);
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_CURRENT_PAGE(state, page) {
    state.current_page = page;
  },
  SET_CURRENT_STATUS(state, status) {
    state.current_status = status;
  },
  SET_PER_PAGE(state, per_page) {
    state.per_page = per_page;
  },
};

export const actions = {
  async getReviews({ commit, state }, { id }) {
    commit('SET_LOADING', true);

    try {
      const { data } = await ReviewService.getUserReviews(
        id,
        state.current_status,
        state.current_page,
        state.per_page
      );
      const reviews = data.data.map((review) => {
        return {
          id: review.id,
          ...review.attributes,
          product: {
            id: review.relationships.product.id,
            name: review.relationships.product.attributes.name,
            image: review.relationships.product.attributes.image,
          },
        };
      });
      commit('SET_REVIEWS', {
        items: reviews,
        meta: data.meta,
        total: data.total,
        per_page: data.perPage,
        last_page: data.lastPage,
        current_page: data.currentPage,
        links: data.links,
      });
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async getReview({ commit }, { id, reviewId }) {
    commit('SET_LOADING', true);

    try {
      const response = await ReviewService.getUserReview(id, reviewId);
      if (response.status === 404) {
        window.location.href = '#/404';
        return;
      }
      const data = {
        id: response.data.id,
        ...response.data.attributes,
        product: {
          id: response.data.relationships.product.id,
          ...response.data.relationships.product.attributes,
          category: {
            id: response.data.relationships.product.relationships.category.id,
            ...response.data.relationships.product.relationships.category.attributes,
            parent: {
              id: response.data.relationships.product.relationships.category.relationships
                .parent?.id,
              ...response.data.relationships.product.relationships.category.relationships
                .parent?.attributes,
            },
          },
          ingredients:
            response.data.relationships.product.relationships.ingredients.data.map(
              (ingredient) => {
                return {
                  id: ingredient.id,
                  ...ingredient.attributes,
                };
              }
            ),
        },
      };
      commit('SET_REVIEW', data);
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async deleteReview({ commit }, { id, reviewId }) {
    if (confirm('Are you sure you want to delete this review')) {
      commit('SET_LOADING', true);
      try {
        await ReviewService.deleteReview(id, reviewId);
        if (router.currentRoute._rawValue.path !== '/reviews') {
          router.push({ name: 'Reviews', params: {} });
          return;
        } else {
          commit('DELETE_REVIEW', reviewId);
        }
        commit('SET_LOADING', false);
        commit('SET_ERROR', null);
      } catch (error) {
        commit('SET_LOADING', false);
        commit('SET_ERROR', getError(error));
      }
    }
  },
  async create({ commit, dispatch }, { payload }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await ReviewService.store(payload);
      const review = ReviewService.constructSimpleReview(data);
      const product = data.relationships.product;
      dispatch('product/updateProductReviews', { review, product }, { root: true }).then(
        () => {
          commit('SET_LOADING', false);
          commit('SET_ERROR', null);
        }
      );
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async toggleLike({ commit, dispatch }, { index, payload }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await ReviewService.toggleLike(payload);

      dispatch('product/toggleReviewLike', { index }, { root: true }).then(() => {
        commit('SET_LOADING', false);
        commit('SET_ERROR', null);
      });
      return data;
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async updateReview({ commit }, { id, reviewId, payload }) {
    commit('SET_LOADING', true);
    try {
      await ReviewService.updateReview(id, reviewId, payload);
      commit('SET_REVIEW', null);
      commit('SET_ERROR', null);
      router.push({ name: 'Reviews', params: {} });
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
};

export const getters = {
  reviews: (state) => state.reviews,
  review: (state) => state.review,
  loading: (state) => state.loading,
  error: (state) => state.error,
  current_page: (state) => state.current_page,
  current_status: (state) => state.current_status,
  per_page: (state) => state.per_page,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
