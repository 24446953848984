import axiosConfig from '@/utils/AxiosConfig';

export default {
  async getCart() {
    return axiosConfig.auth.get('/cart/shopping');
  },
  async getItem(id) {
    return axiosConfig.auth.get(`/cart/shopping/${id}`);
  },
  async addToCart(payload) {
    return axiosConfig.auth.post(`/cart/shopping`, payload);
  },
  async updateItemQuantity(rowId, payload) {
    return axiosConfig.auth.patch(`/cart/shopping/update-quantity/${rowId}`, payload);
  },
  async removeItem(rowId) {
    return axiosConfig.auth.delete(`/cart/shopping/${rowId}`);
  },
  async emptyCart() {
    return axiosConfig.auth.delete(`/cart/shopping/destroy`);
  },
  async saveCart() {
    return axiosConfig.auth.get(`/cart/shopping/save`);
  },
  async restoreCart() {
    return axiosConfig.auth.get(`/cart/shopping/restore`);
  },
  async checkoutCart() {
    return axiosConfig.auth.get(`/cart/shopping/checkout`);
  },
  async updateItemIsFavoriteStatus(rowId, payload) {
    return axiosConfig.auth.patch(
      `/cart/shopping/update-is-favorite-status/${rowId}`,
      payload
    );
  },
};
