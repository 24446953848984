import axiosConfig from '@/utils/AxiosConfig';

export default {
  getArticles(page = 1) {
    return axiosConfig.api.get(`/blog/posts?page=${page}`);
  },
  getArticle(id) {
    return axiosConfig.api.get(`/blog/posts/${id}`);
  },
  getLatestArticles() {
    return axiosConfig.api.get('/blog/posts/latest');
  },
  getPopularArticles(id = null) {
    const relatedTo = id ? `?relatedTo=${id}` : '';
    return axiosConfig.api.get(`/blog/posts/popular${relatedTo}`);
  },
  getPopularArticle() {
    return axiosConfig.api.get('/blog/posts/top');
  },
  getTags() {
    return axiosConfig.api.get('/blog/tags');
  },
  getArticlesByTag(tagId, page = 1) {
    return axiosConfig.api.get(`/blog/tags/${tagId}/posts?page=${page}`);
  },
  getArticleByTag(tagId, id) {
    return axiosConfig.api.get(`/blog/tags/${tagId}/posts/${id}`);
  },
  getLatestArticlesByTag(tagId) {
    return axiosConfig.api.get(`/blog/tags/${tagId}/posts/latest`);
  },
  getPopularArticlesByTag(tagId, id = null) {
    const relatedTo = id ? `?relatedTo=${id}` : '';
    return axiosConfig.api.get(`/blog/tags/${tagId}/posts/popular${relatedTo}`);
  },
  getPopularArticleByTag(tagId) {
    return axiosConfig.api.get(`/blog/tags/${tagId}/posts/top`);
  },
  async toggleLike(payload) {
    return axiosConfig.api.post('/blog/posts/like', payload);
  },
  constructArticle(article) {
    return {
      type: article.type.replace(article.type[0], article.type[0].toUpperCase()),
      id: article.id,
      ...article.attributes,
      date: article.meta.date,
      links: article.links,
      author: {
        id: article.relationships.author.id,
        ...article.relationships.author.attributes,
      },
      tags: article.relationships.tags.map((tag) => {
        return {
          id: tag.id,
          ...tag.attributes,
        };
      }),
    };
  },
};
