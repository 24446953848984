import router from '@/router';
import { getError } from '@/utils/helpers';
import AuthService from '@/services/AuthService';

export const state = {
  user: null,
  loading: false,
  error: null,
  verify_email_message: null,
  reset_password_token: null,
  verify_email_data: null,
  email_verification_sent: false,
  password_reset_email_sent: false,
};

export const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  UPDATE_NEWSLETTER_SUBSCRIBER_STATUS(state, value) {
    state.user.attributes.newsletter_subscriber = value;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    if (error === 'Invalid signature.') {
      state.verify_email_data = null;
    }
    state.error = error;
  },
  SET_VERIFY_EMAIL_MESSAGE(state, message) {
    state.verify_email_message = message;
  },
  SET_RESET_PASSWORD_TOKEN(state, token) {
    state.reset_password_token = token;
  },
  SET_VERIFY_EMAIL_DATA(state, data) {
    state.verify_email_data = data;
  },
  SET_EMAIL_VERIFICATION_SENT(state, value) {
    state.email_verification_sent = value;
  },
  SET_PASSWORD_RESET_EMAIL_SENT(state, value) {
    state.password_reset_email_sent = value;
  },
};

export const actions = {
  async logout({ commit, dispatch }) {
    commit('SET_LOADING', true);
    try {
      await AuthService.logout();
      await dispatch('getAuthUser');
      return router.currentRoute._rawValue.meta.middleware.some((m) => m.name === 'auth')
        ? router.push('/')
        : router.push({ path: router.currentRoute._rawValue.path });
    } catch (error) {
      commit('SET_ERROR', getError(error));
      commit('SET_LOADING', false);
    }
  },
  async getAuthUser({ commit, state, dispatch }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await AuthService.getAuthUser();
      commit('SET_USER', data.data);
      await dispatch('cart/restoreCart', null, { root: true });
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_USER', null);
      commit('SET_ERROR', getError(error));
    } finally {
      await dispatch('setGuest', { value: state.user });
      commit('SET_LOADING', false);
    }
  },
  async attemptLogin({ commit, dispatch }, { email, password }) {
    commit('SET_LOADING', true);
    const payload = {
      email: email,
      password: password,
    };
    try {
      await AuthService.login(payload);
      await dispatch('getAuthUser');
      if (router.currentRoute._rawValue.path === '/login') {
        await router.push('/dashboard');
      }
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async attemptRegister({ commit }, { payload }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await AuthService.registerUser(payload);
      commit('SET_VERIFY_EMAIL_MESSAGE', data.message);
      commit('SET_ERROR', null);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async sendEmailVerification({ commit }, { payload }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await AuthService.sendVerification(payload);
      commit('SET_VERIFY_EMAIL_MESSAGE', data.message);
      commit('SET_ERROR', null);
      commit('SET_EMAIL_VERIFICATION_SENT', true);
    } catch (error) {
      commit('SET_ERROR', getError(error));
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async attemptEmailVerification({ commit, dispatch, state }) {
    commit('SET_LOADING', true);
    const { id, hash, query } = state.verify_email_data;
    try {
      await AuthService.verifyEmail(id, hash, query);
      await dispatch('getAuthUser');
      commit('SET_VERIFY_EMAIL_MESSAGE', null);
      const redirectPath = router.currentRoute._rawValue.query.redirect ?? '/dashboard';
      await router.push(redirectPath);
    } catch (error) {
      commit('SET_ERROR', getError(error));
    } finally {
      commit('SET_EMAIL_VERIFICATION_SENT', false);
    }
  },
  async requestPasswordReset({ commit }, { email }) {
    commit('SET_LOADING', true);
    try {
      const { data } = await AuthService.forgotPassword({ email: email });
      commit('SET_VERIFY_EMAIL_MESSAGE', data.message);
      commit('SET_ERROR', null);
      commit('SET_PASSWORD_RESET_EMAIL_SENT', true);
    } catch (error) {
      commit('SET_ERROR', getError(error));
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async attemptPasswordReset({ commit, state }, { payload }) {
    commit('SET_LOADING', true);
    try {
      payload.token = state.reset_password_token;
      await AuthService.resetPassword(payload);
      await router.push('/login').then(() => {
        commit('SET_RESET_PASSWORD_TOKEN', null);
        commit('SET_ERROR', null);
      });
    } catch (error) {
      commit('SET_ERROR', getError(error));
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateNewsletterSubscriberStatus({ commit }, { value }) {
    try {
      await commit('UPDATE_NEWSLETTER_SUBSCRIBER_STATUS', value);
    } catch (error) {
      commit('guest/SET_ERROR', error, { root: true });
    }
  },
  async setGuest(context, { value }) {
    const guestStatus = value ? 'isGuest' : 'isNotGuest';
    window.localStorage.setItem('guest', guestStatus);
  },
};

export const getters = {
  authUser: (state) => {
    return state.user;
  },
  isAdmin: (state) => {
    return state.user ? state.user.isAdmin : false;
  },
  error: (state) => {
    return state.error;
  },
  loading: (state) => {
    return state.loading;
  },
  loggedIn: (state) => {
    return !!state.user;
  },
  resetPasswordToken: (state) => state.reset_password_token,
  verifyEmailData: (state) => state.verify_email_data,
  emailVerificationSent: (state) => state.email_verification_sent,
  verifyEmailMessage: (state) => state.verify_email_message,
  passwordResetEmailSent: (state) => state.password_reset_email_sent,
  guest: () => {
    const storageItem = window.localStorage.getItem('guest');
    if (!storageItem) return false;
    if (storageItem === 'isGuest') return true;
    if (storageItem === 'isNotGuest') return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
